@import '../../../../colors';

.sources-table-view {
  .custom-checkbox {
    margin-bottom: 0;

    .checkbox {
      margin-right: 0;
    }
  }
}

.archived-row {
  opacity: 0.4;
}

.table-row:hover {
  background-color: $grey-200; /* Utilize another defined variable */
}

.archived-btn {
  width: 35px;
  height: 35px;
  padding: 0;

  svg {
    color: $grey-600;
    width: 25px;
    height: 25px;
  }

  &:hover {
    background: $grey-200;
  }

  &.archived {
    svg {
      color: $orange;
    }
  }

  .MuiTouchRipple-root {
    display: none;
  }
  .table-cell { /* Your existing class */
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // max-width: 200px; /* Example: Set a max width if needed */
    vertical-align: middle; /* Ensure consistent alignment */
  }
}
