@import '../../colors';

.custom-navbar{
  background: #EFEFEF;
  padding: 15px;

  .custom-navbar__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .custom-navbar__logo{
      img{
        max-height: 30px;
        display: block;
      }
    }

    .custom-navbar__menu{
      display: flex;               // Use flexbox for layout
      flex-wrap: nowrap;          // Prevent wrapping
      overflow: hidden;           // Hide overflow if necessary
      white-space: nowrap;        // Prevent text from wrapping
      align-items: center;        // Center items vertically
      .custom-navbar__menu-item{
        font-family: "Frutiger", sans-serif;
        text-transform: none;
        letter-spacing: 0;
        border-radius: 30px;
        color: #606060;
        font-size: 12px;
        font-weight: 400;
        padding: 8px 20px 5px 20px;
        transition: 0.3s ease all;

        svg{
          color: $creditlife-green;
          font-size: 17px;
          margin-top: -3px;
          width: 18px;
        }

        &:not(:last-child){
          margin-right: 10px;
        }

        &:hover{
          background: rgba(0,0,0,0.05);
        }

        &.active{
          background: $creditlife-green;
          color: #fff;
          font-weight: bold;

          svg{
            color: #fff;
          }
        }
      }
    }

    .custom-navbar__user{
      display: flex;
      align-items: center;

      .custom-navbar__user-item{
        width: 30px;
        height: 30px;
        color: #606060;
        background: rgba(0,0,0,0.05);
        border-radius: 30px;
        padding: 0;
        font-size: 15px;
        margin-right: 10px;
      }

      .custom-navbar__user-details{
        display: flex;
        align-items: center;
        cursor: pointer;

        font-weight: 300;
        color: #999;
        font-size: 12px;

        span{
          display: block;
          color: #414140;
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 3px;
        }

        .profile-img svg{
          color: $creditlife-green;
          height: 40px;
          width: 40px;
          margin-right: 10px;
        }
      }
    }
  }
}
